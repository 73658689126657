import React from "react"
import SiteLayout from "../components/SiteLayout"
import { graphql } from "gatsby"
import CategoriesWidget from "../components/CategoriesWidget"
import RecentPostsWidget from "../components/RecentPostsWidget"
import PostEntry from "../components/PostEntry"
import Seo from "../components/Seo"

const User = props => {
  const {
    location,
    data: {
      allWpUser
    },
  } = props

  const user = allWpUser.edges[0].node
  const { name } = user

  return (
    <SiteLayout location={location}>
      <Seo title={`${name}`} />
      <h1>{name}</h1>
      <h2>Latest Posts</h2>
      <RecentPostsWidget />
      <CategoriesWidget />
    </SiteLayout>
  )
}

export default User

export const pageQuery = graphql`
  query user($id: String!) {
    allWpUser(filter: {id: {eq: $id}}) {
      edges {
        node {
          name
          avatar {
            url
          }
          posts {
            nodes {
              id
            }
          }
        }
      }
    }
  }
`
